import React from "react";

export default function Contact() {

  return (
    <section id="contact" className="padding">
      <h2>Like what you see?<br/>You can send me an email</h2>
      <a data-cursor="click" href="mailto:mario.rod.gonz97@gmail.com">mario.rod.gonz97@gmail.com</a>
    </section>
  );

}
