import React from "react";

export default function Services() {
  return (
    <section id="services" className="padding-y">
      <div className="width-100 text-center">

        <h2 className="margin-0">Skills</h2>

        <div className="padding">

          <ul className="web">
            <li className="text-center">Next.js</li>
            <li className="text-center">React</li>
            <li className="text-center">Eleventy</li>
            <li className="text-center">JavaScript</li>
            <li className="text-center">HTML</li>
            <li className="text-center">CSS</li>
            <li className="text-center">SCSS</li>
            <li className="text-center">Tailwind</li>
            <li className="text-center">Sanity CMS</li>
          </ul>

          <ul className="skills">
            <li className="text-center">Wordpress</li>
            <li className="text-center">Accessibility</li>
            <li className="text-center">SEO</li>
            <li className="text-center">UX/UI</li>
          </ul>

          <ul className="other">
            <li className="text-center">Adobe Creative Suite</li>
            <li className="text-center">Figma</li>
            <li className="text-center">Jira</li>
          </ul>

        </div>

      </div>
    </section>
  );
}
