import React from "react";

export default function Intro() {
  return (
    <section id="intro">
      <div className="d-flex align-items-center padding-x">
        <h1 className="width-50">
        Servus! I’m Mario, a&nbsp;Graphic Designer and&nbsp;Web Developer based&nbsp;in Munich
        </h1>
      </div> 
      <div className="emoji position-bottom">
        🍓🎂🍦☺🐈💕💴😍🛝🥑🤣🧸🪐🫶📨🐇✨⛱👌🍻🌿🌍🍓🎂🍦☺🐈💕💴😍🛝🥑🤣🧸
      </div>
    </section>
  );
}
